<template>
  <auth-layout>
    <form>
      <card-item :margin="true">
        {{ $t('share.welcome') }}
        <div class="spacer--20"></div>
        <ab-input-field
          :label="$t('app.email')"
          v-model="email"
          type="email"
          :error="$v.email.$error"
          :errorMessage="$t('forms.valid_email')"
        ></ab-input-field>
        <div class="login__error" v-if="submitStatus">
          <p>{{ submitStatus }}</p>
        </div>
      </card-item>
      <div class="spacer--20"></div>
      <div class="grid-x align-middle">
        <div class="cell auto text-right">
          <button type="submit" @click="handleSubmit" class="button--primary">
            {{ $t('app.login') }}
          </button>
        </div>
      </div>
    </form>
  </auth-layout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout'
import backend from '@/backend'
import { email, required } from 'vuelidate/lib/validators'

export default {
  name: 'SharedPage',
  data () {
    return {
      submitStatus: '',
      email: '',
      token: this.$route.params.pathName
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.submitStatus = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        setTimeout(() => {
          backend
            .post(`${process.env.VUE_APP_URL}/token/public`, { email: this.email, token: this.token })
            .then((response) => {
              const tokenContents = backend.parseJwt(response.data.access_token)
              backend.state.access_token = response.data.access_token
              const url = new URL(tokenContents.url)
              this.$router.push(url.pathname)
            })
            .catch(() => {
              this.submitStatus = this.$t('share.login_error')
            })
        }, 500)
      }
    }
  },
  components: {
    'auth-layout': AuthLayout
  },
  validations: {
    email: {
      required,
      email
    }
  }
}
</script>

<style scoped></style>
